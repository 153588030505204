<template>
  <div>
    <v-row
      justify="center"
      class="mt-4"
    >
      <v-col
        cols="11"
        sm="6"
        lg="6"
        class="mb-4"
      >
        <v-card class="card-box pa-3 mb-4">
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              cols="11"
              class="mb-2"
            >
              <h6 class="heading heading-m-small">
                Billing information
              </h6>
            </v-col>
            <v-col cols="11">
              <BillingInformationComponent />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="11"
        sm="6"
        lg="6"
      >
        <v-card class="card-box py-3 px-0 mb-4">
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              cols="12"
              class="mb-2 px-3"
            >
              <h6 class="heading heading-m-small">
                Payment history
              </h6>
            </v-col>
            <v-col cols="12">
              <PaymentHistoryComponent />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BillingInformationComponent from "@/components/billing/BillingInformationComponent";
import PaymentHistoryComponent from "@/components/billing/PaymentHistoryComponent";
export default {
  components: { BillingInformationComponent, PaymentHistoryComponent },
}
</script>

<style scoped>
div.v-card.card-box {
  box-shadow: none;
  border-radius: 16px;
}
</style>
