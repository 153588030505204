<template>
  <div>
    <v-row v-show="!finishInitLoad">
      <v-col cols="12">
        <ProgressBarComponent :title="''" />
      </v-col>
    </v-row>
    <v-row
      v-if="finishInitLoad && showCard"
      no-gutters
      justify="center"
    >
      <v-col cols="12">
        <v-text-field
          v-model="address"
          :readonly="!editingBillingAddress"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="city"
          :readonly="!editingBillingAddress"
        />
      </v-col>
      <v-col
        cols="6"
        class="pr-2"
      >
        <v-text-field
          v-model="state"
          :readonly="!editingBillingAddress"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="postalCode"
          :readonly="!editingBillingAddress"
        />
      </v-col>
      <v-col
        cols="12"
        class="mb-4"
      >
        <v-btn
          v-if="!editingBillingAddress"
          block
          color="primary"
          ripple
          depressed
          class="btn-radius mt-4"
          @click="editingBillingAddress = true"
        >
          EDIT INFORMATION
        </v-btn>
      </v-col>
      <v-col
        v-if="editingBillingAddress"
        cols="12"
        class="mb-4"
      >
        <v-row
          no-gutters
          justify="space-between"
        >
          <v-col cols="5">
            <v-btn
              block
              color="red lighten-2"
              ripple
              depressed
              class="btn-radius white--text"
              :disabled="loadingSave"
              @click="editingBillingAddress = false"
            >
              CANCEL
            </v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn
              block
              color="primary"
              ripple
              depressed
              :loading="loadingSave"
              class="btn-radius"
              @click="saveBillingAddress"
            >
              SAVE
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="nameOnCard"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="cardNumber"
            />
          </v-col>
          <v-col
            cols="6"
            class="pr-2"
          >
            <v-text-field
              readonly
              :value="cardCvcPlaceholder"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="cardExpiryDate"
            />
          </v-col>
          <v-col
            cols="12"
            class="mb-4"
          >
            <v-btn
              block
              color="red lighten-2"
              ripple
              depressed
              class="btn-radius white--text mt-4"
              :loading="loadingDeleteCard"
              @click="deleteCard"
            >
              DELETE CARD
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="finishInitLoad && !showCard"
      no-gutters
    >
      <v-sheet
        rounded
        color="grey lighten-4"
        class="mx-auto pa-3"
        height="auto"
        width="auto"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <div class="mx-auto text-center">
              <v-icon
                large
                color="warning"
              >
                mdi-alert-circle-outline
              </v-icon>
            </div>
            <div class="mx-auto px-9 text-center">
              You don't have any payment method added
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-row>
  </div>
</template>

<script>
import ProgressBarComponent from "@/components/common/ProgressBarComponent";
import { mapActions } from 'vuex'
export default {
  components: { ProgressBarComponent },
  data: () => ({
    address: null,
    city: null,
    state: null,
    postalCode: null,
    editingBillingAddress: false,
    paymentMethod: null,
    finishInitLoad: false,
    loadingSave: false,
    loadingDeleteCard: false,
  }),
  computed: {
    showCard() {
      return this.paymentMethod?.billing_details;
    },
    nameOnCard() {
      return this.paymentMethod?.billing_details?.name;
    },
    cardNumber() {
      return this.paymentMethod?.billing_details ? `••••••••${this.paymentMethod?.card?.last4}` : '';
    },
    cardCvcPlaceholder () {
      return '•••';
    },
    cardExpiryDate() {
      return `${ this.paymentMethod?.card?.exp_month } / ${ this.paymentMethod?.card?.exp_year }`;
    }
  },
  methods: {
    ...mapActions('profile', ['getUserPaymentMethod', 'updateBillingAddress', 'deletePaymentMethod']),
    async saveBillingAddress() {

      this.loadingSave = true;
      const options = { address: { line1: this.address, city: this.city, state: this.state, postal_code: this.postalCode } };

      const response  = await this.updateBillingAddress(options);

      if(response) {
        this.$toasted.global.showSuccess({message: response.message});
      } else {
        this.$toasted.global.showError({message:  response.data ?  response.data.message : 'Unexpected error'});
      }

      this.editingBillingAddress = false;
      this.loadingSave = false;
    },
    async deleteCard() {

      this.loadingDeleteCard = true;
      const response = await this.deletePaymentMethod();

      if(response) {
        this.$toasted.global.showSuccess({message: response.message});
        this.paymentMethod = null;
      } else {
        this.$toasted.global.showError({message:  response.data ?  response.data.message : 'Unexpected error'});
      }

      this.loadingDeleteCard = false;
    }
  },
  async mounted() {
    this.paymentMethod = await this.getUserPaymentMethod();
    if (this.paymentMethod.billing_details) {
      this.address = this.paymentMethod.billing_details.address.line1;
      this.city = this.paymentMethod.billing_details.address.city;
      this.state = this.paymentMethod.billing_details.address.state;
      this.postalCode = this.paymentMethod.billing_details.address.postal_code;
    }
    this.finishInitLoad = true;
  }
}
</script>
<style>
.btn-radius {
  border-radius: 10px;
}
</style>