<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    :calculate-widths="true"
    :mobile-breakpoint="0"
    :loading="isLoading"
    loading-text="Loading... Please wait"
    class="elevation-0"
  > 
    <template #item.invoiceUrl="{ item }">
      <v-btn
        text
        :loading="isLoading"
        @click="downloadReceiptPdf(item.chargeId)"
      >
        <v-icon color="primary">
          mdi-download-outline
        </v-icon>
      </v-btn>
      <a
        :id="item.chargeId"
        :ref="item.chargeId"
        href=""
        target="_blank"
        class="d-none"
        rel="noopener"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    isLoading: false,
    headers: [
      { text: 'Date', value: 'date', sortable: false,  align: 'start'},
      { text: 'Amount', value: 'amount', sortable: false,  align: 'start'},
      { text: 'Receipt', value: 'invoiceUrl', sortable: false,  align: 'center'},
    ],
    items: []
  }),
  methods: {
    ...mapActions('profile', ['getPaymentHistory', 'getPaymentReceiptPdf']),
    async downloadReceiptPdf(chargeId) {

      let link = this.$refs[chargeId];

      if(!this.isLoading && !link.getAttribute('href').length) {

        this.isLoading = true;
        const response = await this.getPaymentReceiptPdf({ params: { chargeId } });

        if(response) {

          link.setAttribute('href', response.receiptPdfUrl);
          link.click();

        } else {
          this.$toasted.global.showError({message:  response.data ?  response.data.message : 'Unexpected error'});
        }

        this.isLoading = false;
      } else {
        link.click();
      }

    }
  },
  async mounted() {
    this.isLoading = true;
    const response = await this.getPaymentHistory();

    if(response) {
      this.items = response;
    }

    this.isLoading = false;
  }
}
</script>
<style scoped>
.v-data-table >>> .v-data-footer .v-data-footer__select{
  display: none !important;
}
.v-data-table >>> .v-data-footer .v-data-footer__pagination {
  margin-left: 8px !important;
  margin-right: 2px !important;
}
</style>
